var searchKeys = [{
  key: 'flikiid',
  label: 'Fliki ID',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true
}, {
  key: 'id',
  label: 'AI视频ID',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true
}, {
  key: 'username',
  label: '消费用户昵称',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true
}, {
  key: 'button',
  label: '',
  placeholder: '',
  button: true
}];
export { searchKeys };