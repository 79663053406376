var columns = [{
  title: 'AI视频ID',
  dataIndex: 'aivideoid',
  key: 'aivideoid',
  width: '12%',
  scopedSlots: {
    customRender: 'aivideoid'
  },
  align: 'center'
}, {
  title: 'Fliki ID',
  dataIndex: 'flikiid',
  key: 'flikiid',
  width: '10%',
  scopedSlots: {
    customRender: 'flikiid'
  },
  align: 'center'
}, {
  title: '视频链接',
  dataIndex: 'videourl',
  key: 'videourl',
  width: '18%',
  scopedSlots: {
    customRender: 'videourl'
  },
  align: 'center'
}, {
  title: '输入文案',
  dataIndex: 'content',
  key: 'content',
  width: '15%',
  scopedSlots: {
    customRender: 'content'
  },
  align: 'center'
}, {
  title: '消费CBK',
  dataIndex: 'amount',
  key: 'amount',
  width: '8%',
  scopedSlots: {
    customRender: 'amount'
  },
  align: 'center'
}, {
  title: '消费用户昵称',
  dataIndex: 'username',
  key: 'username',
  width: '10%',
  scopedSlots: {
    customRender: 'username'
  },
  align: 'center'
}, {
  title: '创建时间',
  dataIndex: 'createdate',
  key: 'createdate',
  width: '10%',
  scopedSlots: {
    customRender: 'createdate'
  },
  align: 'center'
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: 150,
  scopedSlots: {
    customRender: 'action'
  },
  align: 'center'
}];
export { columns };